/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useEffect } from 'react';
import { isLoggedIn } from '@utils/authV2';
import navigate from '@utils/navigate';
import HomePage from '@routes/Home';

const indexPage = () => {
  const isUserLoggedIn = isLoggedIn();
  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate('/login');
    } else {
      navigate('/');
    }
  }, []);

  return isUserLoggedIn ? <HomePage /> : '';
};

export default indexPage;
